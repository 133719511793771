import Vue from 'vue';
import Vuex from 'vuex';
import _ from "lodash";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state:{
    configs: undefined,
    activeConfigId: undefined,
    tag: ""
  },
  getters:{
    configs: state =>{
      return state.configs;
    },
    activeConfigObject: state => {
      return _.find(state.configs, {id: state.activeConfigId}).configObject
    },
    tag: state => {
      return state.tag;
    }
  },
  mutations:{
    saveConfigs (state, payload){
      state.configs = payload
    },
    setActiveConfigId (state, payload){
      state.activeConfigId = payload
    },
    setTag (state, payload){
      state.tag = payload
    }
  },
  actions:{

  }
});