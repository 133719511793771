<template>
  <div>
    <div class="box" v-for="config in configs" :key="config.id">
      <h2 class="subtitle">{{ config.configObject.meetingTypeFull }}</h2>
      <p v-html="config.configObject.meetingTypeDescription"></p>
      <b-button type="is-primary" outlined @click="navigateToMeetMe(config.id)"
        >Book</b-button
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["configs"]),
  },
  methods: {
    navigateToMeetMe(id) {
      this.$store.commit("setActiveConfigId", id);
      this.$router.push("/booking");
    },
  },
  created() {
    let funcURL =
      process.env.VUE_APP_FUNCTIONS_BASE_URL + ".netlify/functions/get-configs";
    let tag = this.$route.params.tag;
    this.$store.commit("setTag", tag)
    axios
      .get(funcURL, {
        params: {
          tag,
        },
      })
      .then((response) => {
        console.log(response);
        this.$store.commit("saveConfigs", response.data);
      });
  },
};
</script>
