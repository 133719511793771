<template>
  <div>
    <section class="section" id="booking-section">
      <div class="container">
        <div class="columns">
          <div class="column is-4" id="booking-summary">
            <h2 class="subtitle">{{meetingTypeFull}}</h2>
            <ul class="content">
              <li>
                <b-icon icon="clock-time-five" size="is-small"></b-icon>{{this.meetingDuration}} min
              </li>
              <li>
                <b-icon icon="map-marker" size="is-small"></b-icon>{{this.meetingLocation}}
              </li>
              <li class="has-text-success" v-show="meetingSlotSelected">
                <b-icon icon="calendar" size="is-small"></b-icon>
                {{ this.selectedSlotPretty }}
              </li>
              <li v-show="meetingSlotSelected">
                <b-icon icon="earth" size="is-small"></b-icon>
                {{ this.zonePretty }}
              </li>
            </ul>
            <p class="content" v-show="!meetingSlotSelected">
              {{this.meetingTypeDescription}}
            </p>
          </div>
          <div
            class="column"
            id="booking-datepicker"
            v-show="!meetingSlotSelected"
          >
            <b-datepicker
              v-model="selectedDay"
              inline
              :first-day-of-week="1"
              :unselectable-dates="unselectableDates"
              @change-month="updateMonth"
              @change-year="updateYear"
            >            
            <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false">
            </b-loading></b-datepicker>
          </div>
          <div
            class="column"
            id="booking-slotpicker"
            v-show="!meetingSlotSelected"
          >
            <p class="subtitle">{{ this.selectedDayPretty }}</p>
            <ul>
              <li v-for="slot in availableMeetingSlots" :key="slot.s.ts">
                <b-button
                  type="is-primary"
                  outlined
                  @click="chooseMeetingSlot(slot)"
                  >{{
                    slot.s.toLocaleString({
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  }}</b-button
                >
              </li>
            </ul>
          </div>
          <div class="column" id="booking-details" v-show="meetingSlotSelected">
            <b-loading :is-full-page="false" v-model="isLoading2" :can-cancel="false">
            </b-loading>
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <div class="level">
                <div class="level-left">
                  <div class="level-item">
                    <h1 class="subtitle">Enter Details</h1>
                  </div>
                </div>
                <div class="level-right">
                  <div class="level-item">
                    <b-button
                      icon-right="arrow-left-circle-outline"
                      size="is-medium"
                      @click="goBack()"
                      type="is-text is-info"
                    />
                  </div>
                </div>
              </div>

              <div class="level">
                <div class="level-left">
                  <div class="level-item">
                    <ValidationProvider
                      rules="required"
                      name="First Name"
                      v-slot="{ errors, valid }"
                    >
                      <b-field
                        label="First Name"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors"
                      >
                        <b-input v-model="firstName"></b-input>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="level-item">
                    <ValidationProvider
                      rules="required"
                      name="First Name"
                      v-slot="{ errors, valid }"
                    >
                      <b-field
                        label="Last Name"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors"
                      >
                        <b-input v-model="lastName"></b-input>
                      </b-field>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <ValidationProvider
                rules="required|email"
                name="Email"
                v-slot="{ errors, valid }"
              >
                <b-field
                  label="Email"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-input v-model="email" type="email"></b-input>
                </b-field>
              </ValidationProvider>
              <ValidationProvider
                rules="required"
                name="Topic/Context"
                v-slot="{ errors, valid }"
              >
                <b-field
                  label="Topic/Context"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-input v-model="topic" type="textarea"></b-input>
                </b-field>
              </ValidationProvider>
              <b-button @click="handleSubmit(addEvent)"
                >Schedule Event</b-button
              >
            </ValidationObserver>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { DateTime, Interval, LocalZone } from "luxon";
import _ from "lodash";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "axios";
require("dotenv").config();

export default {
  name: "app",
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    nonMeetingDays: {
      type: Array,
      default: function() {
        return [6, 7];
      },
    },
    minMeetingDaysInAdvance: {
      type: Number,
      default: 1,
    },
    meetingWindows: {
      type: Array,
      default: function() {
        return [
          {
            startHour: 9,
            startMinute: 30,
            endHour: 12,
            endMinute: 0,
          },
          {
            startHour: 13,
            startMinute: 0,
            endHour: 16,
            endMinute: 0,
          },
        ];
      },
    },
    preMeetingDuration: {
      type: Number,
      default: 0,
    },
    postMeetingDuration: {
      type: Number,
      default: 0,
    },
    meetingDuration: {
      type: Number,
      default: 30,
    },
    startIncrement: {
      type: Number,
      default: 15,
    },
    maxNumberMeetingsPerWeek: {
      type: Number,
      default: 3,
    },
    freeFloatSlotsAllowed: {
      type: Boolean,
      default: true,
    },
    deepWorkWindows: {
      type: Array,
      default: function() {
        return [
          {
            startHour: 9,
            startMinute: 0,
            endHour: 11,
            endMinute: 30,
          },
          {
            startHour: 13,
            startMinute: 30,
            endHour: 16,
            endMinute: 0,
          },
        ];
      },
    },
    deepWorkDays: {
      type: Array,
      default: function() {
        return [1, 2, 3, 4, 5];
      }, //1 = Monday, ..., 7= Sunday
    },
    minDeepWorkSlots: {
      type: Number,
      default: 3,
    },
    calendarID: {
      type: String,
      default: "primary",
    },
    meetingType: {
      type: String,
      required: true,
    },
    meetingTypeFull: {
      type: String,
      required: true,
    },
    meetingTypeDescription: {
      type: String,
      required: false,
    },
    meetingLocation:{
      type: String,
      default: "Office B73, Building D, Campus Diepenbeek"
    }
  },

  data() {
    return {
      //DatePicker
      isLoading: true,
      isLoading2: false,

      //State
      selectedDay: new Date(),
      month: undefined,
      year: undefined,
      searchHistory: [], //history of months that have been queried already (prevent duplicate GCAl queries)
      calendarData: {},
      meetingSlotSelected: false,

      //New Event info
      selectedSlot: undefined,
      firstName: "",
      lastName: "",
      email: "",
      topic: "",

      busySlots: undefined,
      deepWorkSlots: undefined,
      numberStudentMeetingsBooked: 0,

      items: undefined,
      authorized: false,
      timeMin: new Date(),
      timeMax: new Date(),
      columns: [
        {
          field: "start",
          label: "Start",
        },
        {
          field: "end",
          label: "End",
        },
      ],
    };
  },

  computed: {
    availableMeetingSlots: function() {
      //console.log(this.selectedDay.toString())
      let dateKey = this.getCalendarDataKey(
        this.selectedDay.getFullYear(),
        this.selectedDay.getMonth() + 1,
        this.selectedDay.getDate()
      );
      //console.log(dateKey);
      //console.log(_.get(this.calendarData, [dateKey, 'meetingSlots']))
      // return _.map(
      //   _.get(this.calendarData, [dateKey, 'meetingSlots']),
      //   el => el.meetingSlot
      //   );
      return _(this.calendarData)
        .get([dateKey, "meetingSlots"])
        .filter((el) => el.available)
        .map((el) => el.meetingSlot);
      // if(_.has(this.calendarData, dateKey)){
      //   console.log("dateKey doesn't exist in calendarData");
      //   return [];
      // }
      // else{
      //   return this.calendarData[dateKey].meetingSlots
      // }
    },

    unselectableDates: function() {
      let temp = _.filter(this.calendarData, (day) => {
        return !_.some(day.meetingSlots, "available");
      }).map((day) => {
        return day.date.toJSDate();
      });
      return temp;
    },

    selectedDayPretty: function() {
      return DateTime.fromJSDate(this.selectedDay)
        .setLocale("nl-BE")
        .toLocaleString({
          weekday: "long",
          month: "long",
          day: "numeric",
          year: "numeric",
        });
    },

    availableMeetingSlotsTable: function() {
      return _.map(this.availableMeetingSlots, (slot) => {
        return {
          start: slot.s
            .setLocale("nl-BE")
            .toLocaleString(DateTime.DATETIME_FULL),
          end: slot.e.setLocale("nl-BE").toLocaleString(DateTime.DATETIME_FULL),
        };
      });
    },

    selectedSlotPretty: function() {
      if (typeof this.selectedSlot === "undefined") {
        return "-";
      } else {
        return (
          this.selectedSlot.s.toFormat("HH:mm") +
          " - " +
          this.selectedSlot.e.toFormat("HH:mm") +
          ", " +
          this.selectedSlot.s.setLocale("nl-BE").toLocaleString({
            weekday: "long",
            month: "long",
            day: "numeric",
            year: "numeric",
          })
        );
      }
    },

    zonePretty: function() {
      if (typeof this.selectedSlot === "undefined") {
        return LocalZone.instance.name;
      } else {
        return this.selectedSlot.s.zoneName;
      }
    },
  },

  // Initialization code when vue is created
  created() {
    console.log("bookingcomponent created");
    this.month = this.selectedDay.getMonth() + 1;
    //Month + 1 is needed because this is a JS date object, which starts at 0 for January!
    this.year = this.selectedDay.getFullYear();
    this.updateCalendarData();
    // .catch(error => console.error(error))
    //this.handleClientLoad();
  },

  methods: {
    updateMonth(newMonth) {
      this.month = newMonth + 1;
      //Month + 1 is needed because this is a JS date object, which starts at 0 for January!
      this.updateCalendarData();
    },

    updateYear(newYear) {
      this.year = newYear;
      this.updateCalendarData();
    },

    //storing the google api as a local variable
    // setupApi(gapi) {
    //   // console.log("setupApi")
    //   this.api = gapi;
    // },

    //Helper function which gives you a key based on the year, month and day
    getCalendarDataKey(year, month, day) {
      return DateTime.fromObject({
        year: year,
        month: month,
        day: day,
      }).toMillis();
    },

    updateCalendarData() {
      this.isLoading = true;
      let firstDate = DateTime.fromObject({
        month: this.month,
        year: this.year,
      }).startOf("week");
      if (!_.includes(this.searchHistory, firstDate.toMillis())) {
        let lastDate = DateTime.fromObject({
          month: this.month,
          year: this.year,
        })
          .endOf("month")
          .endOf("week");
        let period = Interval.fromDateTimes(firstDate, lastDate);
        this.initCalendarData(period);
        this.addBusySlots(period)
          .then(() => this.countPlannedMeetings(period))
          .then(() => this.createMeetingSlots(period))
          .then(() => {
            this.searchHistory.push(period.start.toMillis());
            this.isLoading = false;
          });
        // this.$gapi
        //   .getGapiClient() //retrieve google api
        //   .then((gapi) => this.setupApi(gapi)) //store google api object as local variable
        //   .then(() => this.addBusySlots(period)) //retrieve all slots that are no longer available
        //   .then(() => this.countPlannedMeetings(period)) //add information for each day how many meetings of this meetingType are already planned.
        //   .then(() => this.createMeetingSlots(period))
        //   .then(() => {
        //     this.searchHistory.push(period.start.toMillis());
        //   });
      }
    },

    // Initialize the calendarData object
    initCalendarData(period) {
      // Get first day of the week containing the start of the month (start of the period)
      let firstDate = period.start;
      // Get last day of the week containing the end of the month (end of the period)
      let lastDate = period.end;
      // Create an initial object for each day of the month
      for (
        let date = firstDate;
        date <= lastDate;
        date = date.plus({ day: 1 })
      ) {
        // console.log(date.toMillis());
        this.$set(
          this.calendarData,
          this.getCalendarDataKey(date.year, date.month, date.day),
          {
            date: date,
            year: date.year,
            month: date.month,
            day: date.day,
            week: date.weekNumber,
            busySlots: [],
            nPlannedMeetings: 0,
            nPlannedMeetingsThisWeek: 0,
            dwSlots: [],
            meetingSlots: [],
          }
        );
      }
    },

    //Retrieve all slots that are no longer available and store them in the calendarData object
    addBusySlots(period) {
      console.log("addBusySlots");
      console.log(period);
      let funcURL =
        process.env.VUE_APP_FUNCTIONS_BASE_URL +
        ".netlify/functions/get-freebusy";

      let query_object = {
        items: [
          {
            id: "primary",
          },
        ],
        timeMin: period.start.toISO(),
        timeMax: period.end.toISO(),
        timeZone: "Europe/Brussels",
      };

      let prom = axios({
        method: "post",
        url: funcURL,
        data: query_object,
      }).then((response) => {
        console.log(response);
        _.each(response.data, (el) => {
          let busyStart = DateTime.fromISO(el.start);
          let busyEnd = DateTime.fromISO(el.end);
          let busySlot = Interval.fromDateTimes(busyStart, busyEnd);
          let key = this.getCalendarDataKey(
            busyStart.year,
            busyStart.month,
            busyStart.day
          );
          this.calendarData[key].busySlots.push(busySlot);
          console.log("Busyslot added to " + key);
        });
      });
      console.log("addBusySlots completed");
      return prom;
    },

    //Add to calendarData for each day of the month the number of meetings of our predefined type that are already planned.
    //Also count the number of meetings for that specific week
    countPlannedMeetings(period) {
      //retrieve a list of events of a specific meetingType within the selected month
      console.log("countPlannedMeetings");
      console.log(period);
      let funcURL =
        process.env.VUE_APP_FUNCTIONS_BASE_URL +
        ".netlify/functions/get-events";

      let query_object = {
        calendarId: this.calendarID,
        privateExtendedProperty: "meetingType=" + this.meetingType,
        timeMin: period.start.toISO(),
        timeMax: period.end.toISO(),
      };
      let prom = axios({
        method: "post",
        url: funcURL,
        data: query_object,
      }).then((response) => {
        console.log("events received: ", response);
        //replace each event by its date-based key (duplicates will arise for days with multiple events)
        let temp = _.map(response.data, (meeting) => {
          let date = DateTime.fromISO(meeting.start.dateTime);
          return this.getCalendarDataKey(date.year, date.month, date.day);
        });
        //count how many times each value appears
        temp = _.countBy(temp);
        //Add the number of meetings of our meetingType on a specific day to calendarData
        _.each(temp, (val, key) => {
          this.calendarData[key].nPlannedMeetings = val;
        });
        //Now count at weekbasis
        _.each(this.calendarData, (day) => {
          day.nPlannedMeetingsThisWeek = _(this.calendarData)
            .filter((day2) => {
              return day2.week == day.week;
            })
            .reduce((total, day3) => {
              return total + day3.nPlannedMeetings;
            }, 0);
        });
      });

      return prom;
    },

    createMeetingSlots(period) {
      this.addAvailableDWSlots(period);
      this.addDWSlotsToBusySlots(period);
      this.initAvailableMeetingSlots(period);
      this.determineSlotAvailability(period);
    },

    //Add all the DW slots that are still possible to the calendarData based on the given DWWindows
    addAvailableDWSlots(period) {
      let firstDate = period.start;
      let lastDate = period.end;
      // console.log("addAvailableDWSlots");
      //voor iedere dag van de maand
      for (
        let date = firstDate;
        date <= lastDate;
        date = date.plus({ day: 1 })
      ) {
        //bepaal de key voor deze dag
        let dateKey = this.getCalendarDataKey(date.year, date.month, date.day);
        //Indien de huidige dag tot de weekdagen hoort waarop deepwork plaatsvindt
        if (_.includes(this.deepWorkDays, date.weekday)) {
          //voor ieder deepworkwindow
          _.each(this.deepWorkWindows, (window) => {
            //creëer potentieel deepwork slot
            let start = date.set({
              hour: window.startHour,
              minute: window.startMinute,
            });
            let end = date.set({
              hour: window.endHour,
              minute: window.endMinute,
            });
            let i = Interval.fromDateTimes(start, end);
            // console.log("dwInterval: "+i.toString());
            // Controleer of deepworkSlot niet overlapt met een busySlot
            let overlaps = _.some(
              this.calendarData[dateKey].busySlots,
              (busySlot) => busySlot.overlaps(i)
            );
            // Indien deepworkSlot niet overlapt en nog mogelijk is, dan slaan we dit op in calendarData
            if (!overlaps) {
              this.calendarData[dateKey].dwSlots.push(i);
            }
          });
        }
      }
    },

    numberDWSlotsThisWeek(date) {
      //console.log(date.toString())
      let firstDayOfWeek = date.startOf("week");
      // console.log("firstDayOfWeek:" + firstDayOfWeek.toString())
      let lastDayOfWeek = date.endOf("week");
      // console.log("lastDayOfWeek:" + lastDayOfWeek.toString())
      let nDWSlots = 0;
      for (
        let day = firstDayOfWeek;
        day <= lastDayOfWeek;
        day = day.plus({ day: 1 })
      ) {
        let n_slots = _.size(
          this.calendarData[
            this.getCalendarDataKey(day.year, day.month, day.day)
          ].dwSlots
        );
        // console.log(day.toString() + " : " + n_slots)
        nDWSlots += n_slots;
      }
      // console.log(nDWSlots);
      return nDWSlots;
    },

    addDWSlotsToBusySlots(period) {
      let firstDate = period.start;
      let lastDate = period.end;
      for (
        let date = firstDate;
        date <= lastDate;
        date = date.plus({ day: 1 })
      ) {
        //bepaal de key voor deze dag
        let dateKey = this.getCalendarDataKey(date.year, date.month, date.day);
        let day = this.calendarData[dateKey];
        if (this.numberDWSlotsThisWeek(day.date) <= this.minDeepWorkSlots) {
          // console.log("Add DW slots to busyslots!")
          day.busySlots = _.concat(day.busySlots, day.dwSlots);
        }
      }
    },

    //Creëert alle mogelijke meetingslots die niet overlappen met een busyslot en zonder verdere beperkingen.
    // Slaat de meetingslots op in CalendarData
    initAvailableMeetingSlots(period) {
      let firstDate = period.start;
      let lastDate = period.end;
      //voor iedere dag van de maand
      for (
        let date = firstDate;
        date <= lastDate;
        date = date.plus({ day: 1 })
      ) {
        // console.log(date.toString());
        //bepaal de key voor deze dag
        let dateKey = this.getCalendarDataKey(date.year, date.month, date.day);
        //Creëer meetingslots voor iedere meeting window
        _.forEach(this.meetingWindows, (meetingWindow) => {
          //Maak een meetingSlot op basis van het meeting window
          let meetingInterval = Interval.fromDateTimes(
            date.set({
              hour: meetingWindow.startHour,
              minute: meetingWindow.startMinute,
            }),
            date.set({
              hour: meetingWindow.endHour,
              minute: meetingWindow.endMinute,
            })
          );
          // Verwijder de gedeeltes die overlappen met een busy moment
          let meetingSlots = meetingInterval.difference(
            ...this.calendarData[dateKey].busySlots
          );
          // Voor ieder van de resterende slots
          _.forEach(meetingSlots, (meetingSlot) => {
            //splits op in slots van de juiste grootte en voeg toe aan de finale lijst van meetingslots
            this.calendarData[dateKey].meetingSlots = _.concat(
              this.calendarData[dateKey].meetingSlots,
              this.splitMeetingSlot(meetingSlot)
            );
          });
        });
      }
    },

    //Neemt een venster dat beschikbaar is voor meetingslots en splitst het op in mogelijke meetingslots. Geeft deze array van
    // mogelijke meetingslots terug
    splitMeetingSlot(meetingSlot) {
      // console.log("Meetingslot received: " + meetingSlot.toString());
      let meetingSlots = [];
      //Begin bij startmoment
      let startTime = meetingSlot.start;
      // console.log("startTime: "+startTime.toString());
      //Test of individueel slot in geheel slot valt en verhoog telkens met startIncrement
      for (
        startTime;
        !meetingSlot.isBefore(
          startTime.plus({ minutes: this.meetingDuration })
        );
        startTime = startTime.plus({ minutes: this.startIncrement })
      ) {
        // console.log("startTime: "+startTime.toString());
        meetingSlots.push(
          Interval.fromDateTimes(
            startTime,
            startTime.plus({ minutes: this.meetingDuration })
          )
        );
      }

      meetingSlots = _.map(meetingSlots, (meetingSlot) => {
        return {
          meetingSlot: meetingSlot,
          preceded: true,
          succeeded: true,
          available: true,
        };
      });
      //In case there are any meetingSlots, set the flags for the first and last meetingSlot
      if (_.size(meetingSlots) > 0) {
        _.head(meetingSlots)["preceded"] = false;
        _.last(meetingSlots)["succeeded"] = false;
      }

      return meetingSlots;
    },

    determineSlotAvailability(period) {
      let firstDate = period.start;
      let lastDate = period.end;
      for (
        let date = firstDate;
        date <= lastDate;
        date = date.plus({ day: 1 })
      ) {
        //bepaal de key voor deze dag
        let dateKey = this.getCalendarDataKey(date.year, date.month, date.day);
        let day = this.calendarData[dateKey];
        _.each(day.meetingSlots, (slot) => {
          //If this is a day in the past or not enough in the future, then set available to false
          if (
            day.date <
            this.shiftDateWithNMeetingDays(
              DateTime.local(),
              this.minMeetingDaysInAdvance
            )
          ) {
            slot.available = false;
          }
          //If the day of this slot is a non-meeting day, set available to false
          if (_.includes(this.nonMeetingDays, day.date.weekday)) {
            //incorrect because date.weekday codes monday as 1 and sunday as 7! Javascript Date codes sunday as 0, monday as 1 and saturday as 6!
            slot.available = false;
          }
          //If the maximum number meetings this week has already been reached, set availability to false
          else if (
            day.nPlannedMeetingsThisWeek >= this.maxNumberMeetingsPerWeek
          ) {
            slot.available = false;
          }
          //If freeFloatSlots are not allowed, set available to false for free floating slots
          else if (!this.freeFloatSlotsAllowed && slot.available) {
            slot.available = !slot.preceded || !slot.succeeded;
          }
        });
      }
    },

    shiftDateWithNMeetingDays(date, nMeetingDays) {
      let i = 0;
      while (i < nMeetingDays) {
        date = date.plus({ days: 1 });
        if (!_.includes(this.nonMeetingDays, date.weekday)) {
          i++;
        }
      }
      return date;
    },

    chooseMeetingSlot(meetingSlot) {
      this.selectedSlot = meetingSlot;
      this.meetingSlotSelected = true;
    },

    goBack() {
      this.selectedSlot = undefined;
      this.meetingSlotSelected = false;
    },

    addEvent() {
      let event = {
        calendarId: this.calendarID,
        resource: {
          summary:
            this.firstName + " " + this.lastName + ": " + this.meetingTypeFull,
          location: "Office B73, Building D, Campus Diepenbeek",
          description: this.topic,
          start: {
            dateTime: this.selectedSlot.s.toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
            timeZone: this.selectedSlot.s.zoneName,
          },
          end: {
            dateTime: this.selectedSlot.e.toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
            timeZone: this.selectedSlot.s.zoneName,
          },
          status: "tentative",
          extendedProperties: {
            private: {
              meetingType: this.meetingType,
            },
          },
          // 'recurrence': [
          //   'RRULE:FREQ=DAILY;COUNT=2'
          // ],
          attendees: [{ email: this.email }],
          reminders: {
            useDefault: false,
            overrides: [
              { method: "email", minutes: 24 * 60 },
              { method: "popup", minutes: 10 },
            ],
          },
        },
      };

      console.log(event);
      let funcURL =
        process.env.VUE_APP_FUNCTIONS_BASE_URL + ".netlify/functions/add-event";

      this.isLoading2 = true;
      axios({
        method: "post",
        url: funcURL,
        data: event,
      })
        .then((response) => {
          console.log("succes: ", response);
          let data = JSON.parse(response.data);
          let startTime = DateTime.fromISO(data.start.dateTime).setZone(data.start.timeZone).toLocaleString(Object.assign(DateTime.DATETIME_MED,{weekday:'long'}))
          let endTime = DateTime.fromISO(data.end.dateTime).setZone(data.end.timeZone).toLocaleString(Object.assign(DateTime.DATETIME_MED,{weekday:'long'}))
          console.log(startTime)
          this.isLoading2 = false;
          this.$buefy.dialog.alert({
            title: "Meeting Request Succesfully Booked",
            message:
              "You have set-up the following meeting. </br></br>" +
              "<b>Meeting type: </b>" + this.meetingTypeFull +"</br>" +
              "<b>Location:</b> "+data.location+"</br>"+
              "<b>Start time: </b>"+startTime+"</br>"+
              "<b>End time: </b>"+endTime+"</br>"+
              "<b>Time zone: </b>"+data.start.timeZone+"</br>"+
              "</br>A calendar invite has been sent to the provided email",
            confirmText: "Back Home",
            onConfirm: this.goHome,
          });
          
        })
        .catch((error) => {
          console.log(error);
          this.isLoading2 = false;
          this.$buefy.dialog.alert({
            title: "Failed to book meeting request",
            message:
              "Sorry, something went wrong. Please try again. </br> If the problem persists, please contact me directly.",
            confirmText: "Back Home",
            onConfirm: this.goHome,
            type: 'is-danger'
          });
          
        });

      // this.api.client.calendar.events
      //   .insert({
      //     // auth: auth,
      //     calendarId: this.calendarID,
      //     resource: event,
      //   })
      //   .then((response) => {
      //     console.log("test");
      //     console.log(response);
      //   });
      // , function(err, event) {
      //   if (err) {
      //     console.log('There was an error contacting the Calendar service: ' + err);
      //     return;
      //   }
      //   console.log('Event created: %s', event.htmlLink);
      // });
    },

    goHome() {
      this.$router.push("/");
    },

    /**
     *  On load, called to load the auth2 library and API client library.
     */
    /*       handleClientLoad() {
        // this.api.load('client:auth2', this.initClient);
      }, */

    /**
     *  Initializes the API client library and sets up sign-in state
     *  listeners.
     */
    /*       initClient() {
        // let vm = this;

        // vm.api.client.init({
        //   apiKey: API_KEY,
        //   clientId: CLIENT_ID,
        //   discoveryDocs: DISCOVERY_DOCS,
        //   scope: SCOPES
        // }).then(_ => {
        //   // Listen for sign-in state changes.
        //   vm.api.auth2.getAuthInstance().isSignedIn.listen(vm.authorized);
        // });
      }, */

    getData() {
      if (this.numberStudentMeetingsBooked >= this.maxNumberStudentMeetings) {
        this.availableMeetingSlots = [];
        return true;
      }
      this.timeMin.setHours(0, 0, 0, 0);
      this.timeMax.setHours(23, 59, 59, 99);
      this.api.client.calendar.freebusy
        .query({
          items: [
            {
              id: "primary",
            },
          ],
          timeMin: this.timeMin.toISOString(),
          timeMax: this.timeMax.toISOString(),
          timeZone: "Europe/Brussels",
        })
        .then((response) => {
          this.items = this.syntaxHighlight(
            response.result.calendars.primary.busy
          );
          this.busySlots = _.map(
            response.result.calendars.primary.busy,
            (el) => {
              return Interval.fromDateTimes(
                DateTime.fromISO(el.start),
                DateTime.fromISO(el.end)
              );
            }
          );
          this.removeDeepWorkSlotsDuringBusyMoments();
          this.removeSlotsDuringBusyMoments();
          if (_.size(this.deepWorkSlots) < this.minDeepWorkSlots) {
            this.removeFreeFloatingSlots();
          }
        });
    },
  },
};
</script>

<style></style>
