import Vue from "vue";
import App from "./App.vue";
import router from "./router";

//import Buefy
import Buefy from "buefy";
import "buefy/dist/buefy.css";
Vue.use(Buefy);

//import Vee-Validate
import "./vee-validate";

//import vuex
import {store} from "./store/store";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
