<template>
  <div id="app">
    <section class="section">

    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title">Book a Meeting</h1>  
        </div>
      </div>
      <div class="level-right">
        <b-button type="is-primary" outlined @click="navigateToHome"
        >Home</b-button>
      </div>
    </div>

      <router-view></router-view>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["tag"]),
  },
  methods: {
    navigateToHome() {
      this.$router.push("/"+this.tag);
    },
  }
};
</script>

<style></style>
