<template>
  <div>
    <booking-component
      v-bind = "activeConfigObject"
    ></booking-component>
  </div>
</template>

<script>
import BookingComponent from "../components/BookingComponent.vue";

import {mapGetters} from "vuex";


export default {
  components: {
    "booking-component": BookingComponent,
  },
  computed: {
    ...mapGetters([
      'activeConfigObject'
    ])
  },
};
</script>
