import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Setup from "../views/Setup.vue";
import MeetMe from "../views/MeetMe.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/setup-link",
    name: "Setup Link",
    component: Setup,
  },
  {
    path: "/booking",
    name: "booking",
    component: MeetMe,
    props: true
  },
  {
    path: "/",
    component: Home,
    children: [
      {
        path: ':tag',
        name: "Home",
        component: Home
      }
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
