<template>
  <div>
    <h1>Setup Link</h1>
    <p>Current Status: {{ status }}</p>
    <section>
      <div class="buttons">
        <b-button
          type="is-primary"
          outlined
          :disabled="!this.verified || this.status == this.STATUS_OK"
          @click="authenticateWithGoogle"
          >Sign In</b-button
        >
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
const STATUS_RETRIEVING = "Verifying Link";
const STATUS_OK = "Connected to Agenda";
const STATUS_NOK = "Not Connected to Agenda";

export default {
  data() {
    return {
      status: STATUS_RETRIEVING,
      verified: false,
    };
  },
  methods: {
    authenticateWithGoogle() {
      // let funcURL = "http://localhost:9000/.netlify/functions/setup-link";
      let funcURL = process.env.VUE_APP_FUNCTIONS_BASE_URL + ".netlify/functions/setup-link";
      window.location.href = funcURL
    },
  },
  created() {
    this.STATUS_RETRIEVING = STATUS_RETRIEVING;
    this.STATUS_OK = STATUS_OK;
    this.STATUS_NOK = STATUS_NOK;
  },
  mounted() {
    console.log("setup view is mounted");
    let funcURL = process.env.VUE_APP_FUNCTIONS_BASE_URL + ".netlify/functions/verify-link";
    // let funcURL = "http://localhost:9000/.netlify/functions/verify-link";
    axios.get(funcURL).then((res) => {
      console.log(res);
      if (res.data.number_refresh_tokens == 1) {
        this.status = STATUS_OK;
      } else {
        this.status = STATUS_NOK;
      }
      this.verified = true;
    });
  },
};
</script>
